/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.footer__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4.8rem;
  position: relative;
  background-color: #fff;
  width: 100% !important;

  @include media('<=tablet') {
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
  }

  &__logo {
    @include media('<=tablet') {
      display: none;
    }
  }
}

.footer__socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.footer__text__font {
  color: #3c3c3c;
  margin: 0;
}

.footer__text__links {
  color: #2a2a2a;
  margin: 0;
}
