.secondColumnAd {
  border: 1px solid #c2cbd1;
  width: 300px;
  height: 250px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 11rem;
  background-color: #ffffff;

  &__text {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
}
