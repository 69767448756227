.column__layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  margin: 0;
  padding: 2rem;
  padding-top: 1rem;
  // padding-left: 2rem;
  width: 100%;

  @include media('<=phone') {
    padding: 1rem;
  }

  .first__column__section {
    width: 20%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &--settings {
      width: 18%;
    }

    @include media('<=tablet') {
      display: none;
    }
  }
  .second__column__section {
    width: 1000px;
    height: 100%;

    @include media('<=tablet') {
      width: 100%;
    }
  }

  .third__column__section {
    width: 16%;
    margin-top: 2.5rem;

    @include media('<=tablet') {
      display: none;
    }
  }
}

.news__feed__nav__section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;

}
