.firstColumnAd {
  width: 180px;
  height: 600px;
  border: #000000 1px solid;
  border-radius: 5px;
  background-color: #183E4B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-left: 0rem;
  box-shadow: 0px 0px 10px 0px rgba(145, 107, 107, 0.1);

  &__text {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
}
