.navbar__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem 2.5rem;
  position: relative;
  background-color: #fff;
  width: 100% !important;

  &--headerV2 {
    @include media('<=tablet') {
      padding: 0 2.5rem;
    }

    @include media('<=phone') {
      padding: 0 1rem;
    }
  }

  &__form {
    width: 70%;

    @include media('<=tablet') {
      width: 100%;
    }
  }
}

.navbar__profile__section {
  @include media('<=tablet') {
    display: none;
  }
}

.nav__bar__profile {
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-top: 1rem;
}
